<template>
  <form v-on:submit="submit">
    <img class="mb-4" src="../assets/demo3.png" alt="" width="230">

    <input type="text" class="form-control input-text-medium" v-bind:class="{'is-invalid': isInvalidFirstName}" id="first_name" placeholder="Ім`я" v-model="first_name">

    <div class="mt-2">
      <input type="text" class="form-control input-text-medium" v-bind:class="{'is-invalid': isInvalidLastName}" id="last_name" placeholder="Прізвище" v-model="last_name">
    </div>

    <div class="mt-2">
      <input type="password" class="form-control input-text-medium" v-bind:class="{'is-invalid': isInvalidPassword}" id="password" placeholder="Пароль" v-model="password">
    </div>

    <div class="mt-2">
      <input type="password" class="form-control input-text-medium" v-bind:class="{'is-invalid': isInvalidPassword}" id="password" placeholder="Пароль повторно" v-model="passwordConfirm">
    </div>

    <button class="w-100 btn btn-lg btn-dark mt-3" type="submit">Реєстрація</button>
    <p class="mt-5 text-muted">Вхід</p>
  </form>
</template>

<script>

export default {
  name: 'IndexView',
  data() {
    return {
      first_name: '',
      last_name: '',
      country: '',
      password: '',
      passwordConfirm: '',
      isInvalidFirstName: false,
      isInvalidLastName: false,
      isInvalidPassword: false,
      formInvalid: false,
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();

      this.isInvalidFirstName = false;
      this.isInvalidLastName = false;
      this.isInvalidPassword = false;
      this.formInvalid = false;

      if(!this.first_name) {
        this.isInvalidFirstName = true;
        this.formInvalid = true;
      }
      if(this.first_name.length < 1) {
        this.isInvalidFirstName = true;
        this.formInvalid = true;
      }

      if(!this.last_name) {
        this.isInvalidLastName = true;
        this.formInvalid = true;
      }
      if(this.last_name.length < 1) {
        this.isInvalidLastName = true;
        this.formInvalid = true;
      }

      if(!this.password) {
        this.isInvalidPassword = true;
        this.formInvalid = true;
      }
      if(this.password.length < 1) {
        this.isInvalidPassword = true;
        this.formInvalid = true;
      }

      if(!this.passwordConfirm) {
        this.isInvalidPassword = true;
        this.formInvalid = true;
      }
      if(this.passwordConfirm.length < 1) {
        this.isInvalidPassword = true;
        this.formInvalid = true;
      }

      if(this.password !== this.passwordConfirm) {
        this.isInvalidPassword = true;
        this.formInvalid = true;
      }

      if(this.formInvalid) {
        return;
      }

      this.loader = true;
      this.$http.post(`${process.env.VUE_APP_API_URL}/auth/complete-signup/${this.$route.params.request_id}`, {
        first_name: this.first_name,
        last_name: this.last_name,
        country: this.country,
        password: this.password,
      }).then((response) => {
        const data = response.data;
        window.localStorage.setItem('token', data.token);
        window.location.href = `${data.redirect_url}`;
      })
    }
  }
}
</script>
