<template>
  <div class="panel">
    <div class="panel-body">
      <form v-on:submit="submit">
        <img class="mb-4" src="../assets/demo3.png" alt="" width="230">

        <input type="text" class="form-control" v-bind:class="{'is-invalid': isInvalid}" id="code" placeholder="Код підтвердження" v-model="code">

        <button class="w-100 btn btn-lg btn-dark mt-3" type="submit">Далі</button>
        <p class="mt-5 text-muted">Вхід</p>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'IndexView',
  data() {
    return {
      code: '',
      isInvalid: false,
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();

      this.isInvalid = false;

      if(!this.code) {
        this.isInvalid = true;
        return;
      }
      if(this.code.length < 1) {
        this.isInvalid = true;
        return;
      }

      this.$http.post(`${process.env.VUE_APP_API_URL}/auth/code-confirm/${this.$route.params.request_id}`, {
        code: this.code,
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          const request = data.request;
          this.$router.push({name: 'Signup', params: {request_id: request.id}});
        } else {
          this.isInvalid = true;
        }
      })
    }
  }
}
</script>
