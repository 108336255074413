import { createWebHistory, createRouter } from "vue-router";
import IndexView from "@/components/IndexView";
import LoginView from "@/components/LoginView";
import CodeView from "@/components/CodeView";
import SignupView from "@/components/SignupView";
import PasswordView from "@/components/PasswordView";
import OauthView from "@/components/OauthTelegramView.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: IndexView,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginView,
    },
    {
        path: "/code/:request_id",
        name: "Code",
        component: CodeView,
    },
    {
        path: "/signup/:request_id",
        name: "Signup",
        component: SignupView,
    },
    {
        path: "/password/:request_id",
        name: "Password",
        component: PasswordView,
    },
    {
        path: "/oauth/telegram",
        name: "Oauth",
        component: OauthView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
