<template>
  <form v-on:submit="submit">
    <img src="../assets/demo3.png" alt="" class="mb-4" width="230">

    <input type="password" class="form-control" v-bind:class="{'is-invalid': isInvalid}" id="password" placeholder="Пароль" v-model="password">

    <button class="w-100 btn btn-lg btn-dark mt-3" type="submit">Далі</button>
    <p class="mt-5 text-muted">Вхід</p>
  </form>
</template>

<script>

export default {
  name: 'IndexView',
  data() {
    return {
      password: '',
      isInvalid: false,
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();

      this.isInvalid = false;

      if(!this.password) {
        this.isInvalid = true;
        return;
      }
      if(this.password.length < 1) {
        this.isInvalid = true;
        return;
      }

      this.loader = true;
      this.$http.post(`${process.env.VUE_APP_API_URL}/auth/complete-login/${this.$route.params.request_id}`, {
        password: this.password,
      }).then((response) => {
        const data = response.data;
        if(data.success) {
          window.localStorage.setItem('token', data.token);
          window.location.href = `${data.redirect_url}`;
        } else {
          this.loader = false;
          this.isInvalid = true;
        }
      })
    }
  }
}
</script>
