import App from './App.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/signin.css";
import router from './router';
import * as Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

const app = Vue.createApp(App);

app.use(VueAxios, axios);
app.use(router);

app.mount('#app')
