<template>
  <div class="panel">
    <div class="panel-body">
      <div v-if="loader">
        <img width="200" src="../assets/spinner.svg"/>
        <h3>Перевірка даних...</h3>
      </div>
      <div v-if="error">
        <h3>Помилка при перевірці даних</h3>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OauthTelegramView',
  data() {
    return {
      loader: true,
      error: false,
    }
  },
  async mounted() {
    const query = this.$route.query;

    this.$http.post(`${process.env.VUE_APP_API_URL}/auth/check-telegram-auth`, query).then((response) => {
      const data = response.data;

      if (data.success) {
        window.localStorage.setItem('token', data.token);



      } else {
        this.loader = false;
        this.error = true;
      }
    }).catch(()=> {
      this.loader = false;
      this.error = true;
    })
  },
  methods: {}
}
</script>
